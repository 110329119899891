import pg from "pg";
import { drizzle } from "drizzle-orm/node-postgres";
import * as schema from "./schema";

const { PGHOST, PGDATABASE, PGUSER, PGPASSWORD } = process.env;

const pool = new pg.Pool({
  host: PGHOST,
  database: PGDATABASE,
  user: PGUSER,
  password: PGPASSWORD,
  port: 5432,
  ssl: true,
});

const db = drizzle(pool, { schema: schema });

export default db;

import { SQL, relations } from "drizzle-orm";
import {
  pgTable,
  text,
  pgEnum,
  timestamp,
  uniqueIndex,
  PgSelect,
  PgColumn,
  varchar,
  boolean,
  integer,
  serial,
  index,
  primaryKey,
} from "drizzle-orm/pg-core";
import { createId } from "@paralleldrive/cuid2";

export function withPagination<T extends PgSelect>(
  qb: T,
  orderByColumn: PgColumn | SQL | SQL.Aliased,
  page = 1,
  pageSize = 3,
) {
  return qb
    .orderBy(orderByColumn)
    .limit(pageSize)
    .offset((page - 1) * pageSize);
}

export const planEnum = pgEnum("plan", ["free", "premium"]);

export const userRoles = pgEnum("user_role", ["admin", "member", "saas"]);
/**
 * Users of the application
 */
export const usersTable = pgTable(
  "users",
  {
    id: varchar("id", { length: 128 })
      .$defaultFn(() => createId())
      .primaryKey(),
    firstName: text("first_name"),
    lastName: text("last_name"),
    email: text("email").unique().notNull(),
    authId: varchar("auth_id", { length: 128 }).notNull(),
    plan: planEnum("plan").notNull().default("free"),
    isOnboardingComplete: boolean("is_onboarding_complete")
      .notNull()
      .default(false),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      authIdx: uniqueIndex("auth_idx").on(table.authId),
    };
  },
);

export type InsertUser = typeof usersTable.$inferInsert;
export type SelectUser = typeof usersTable.$inferSelect;
export type SelectUserWithPreferences = SelectUser & {
  preferences: SelectUserPreference;
};

/**
 * Categories for prompts
 * A prompt can have multiple categories
 */
export const promptCategoriesTable = pgTable(
  "prompt_categories",
  {
    id: serial("id").primaryKey(),
    name: text("name").unique().notNull(),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      nameIdx: uniqueIndex("name_idx").on(table.name),
    };
  },
);

export type InsertPromptCategory = typeof promptCategoriesTable.$inferInsert;
export type SelectPromptCategory = typeof promptCategoriesTable.$inferSelect;

export const promptVisibilityEnum = pgEnum("prompt_visibility", [
  "public",
  "private",
  "internal",
]);

/**
 * Prompts for generating content
 */
export const promptsTable = pgTable(
  "prompts",
  {
    id: serial("id").primaryKey(),

    name: text("name").notNull(),
    description: text("description"),
    cost: integer("cost").notNull().default(0),
    content: text("content").notNull(),
    exampleSource: text("example_source"),
    exampleOutput: text("example_output"),
    exampleInput: text("example_input"),
    ownerId: varchar("owner_id", { length: 128 }).notNull(),
    visibility: promptVisibilityEnum("visibility").default("public").notNull(),
    usesCount: integer("uses_count").notNull().default(0),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      ownerIdIdx: index("owner_id_idx").on(table.ownerId),
    };
  },
);

export type InsertPrompt = typeof promptsTable.$inferInsert;
export type SelectPrompt = typeof promptsTable.$inferSelect;

/**
 * Join table for prompts and categories
 */
export const promptCategoryJoinTable = pgTable(
  "prompt_category_join",
  {
    promptId: integer("prompt_id").notNull(),
    categoryId: integer("category_id").notNull(),
  },
  (table) => {
    return {
      pk: primaryKey({ columns: [table.promptId, table.categoryId] }),
    };
  },
);

export type InsertPromptCategoryJoin =
  typeof promptCategoryJoinTable.$inferInsert;
export type SelectPromptCategoryJoin =
  typeof promptCategoryJoinTable.$inferSelect;

export const audioType = pgEnum("audio_type", ["recording", "file"]);
export const audioServices = pgEnum("audio_service", [
  "aws",
  "gcp",
  "deepgram",
  "whisper",
]);

/**
 * Audios uploaded or recorded by users
 */
export const audiosTable = pgTable("audios", {
  id: varchar("id", { length: 128 })
    .$defaultFn(() => createId())
    .primaryKey(),
  ownerId: varchar("owner_id", { length: 128 }).notNull(),
  name: text("name").notNull(),
  originalName: text("original_name").notNull(),
  url: text("url").notNull(),
  duration: integer("duration"),
  detectedLanguage: varchar("detected_language", { length: 10 }),
  transcribed: boolean("transcribed").notNull().default(false),
  mimeType: varchar("mime_type", { length: 128 }),
  type: audioType("type").notNull(),
  service: audioServices("service").notNull(),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at")
    .notNull()
    .$onUpdate(() => new Date()),
});

export type InsertAudio = typeof audiosTable.$inferInsert;
export type SelectAudio = typeof audiosTable.$inferSelect;

/**
 * Transcripts of audios
 */
export const audioTranscriptsTable = pgTable(
  "audio_transcripts",
  {
    id: varchar("id", { length: 128 })
      .$defaultFn(() => createId())
      .primaryKey(),
    audioId: varchar("audio_id", { length: 128 }).notNull(),
    plainText: text("plain_text").notNull(),
    paragraphs: text("paragraphs").notNull(),
    confidence: integer("confidence"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      audioIdx: uniqueIndex("audio_idx").on(table.audioId),
    };
  },
);

export type InsertAudioTranscript = typeof audioTranscriptsTable.$inferInsert;
export type SelectAudioTranscript = typeof audioTranscriptsTable.$inferSelect;

/**
 * Workspaces for organizing content
 */
export const workspacesTable = pgTable(
  "workspaces",
  {
    id: varchar("id", { length: 128 })
      .$defaultFn(() => createId())
      .primaryKey(),
    name: text("name").notNull(),
    ownerId: varchar("owner_id", { length: 128 }).notNull(),
    goal: text("goal"),
    slug: varchar("slug", { length: 512 }).notNull(),
    language: varchar("language", { length: 10 }).notNull().default("en"),
    interests: text("interests"),
    tone: varchar("tone", { length: 50 }),
    formality: varchar("formality", { length: 50 }),
    onboarded: boolean("onboarded").notNull().default(false),
    current: boolean("current").notNull().default(false),
    summary: text("summary"),
    frequency: varchar("frequency", { length: 4 }),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    // Unique ownerId and name
    return {
      ownerNameIdx: uniqueIndex("owner_name_idx").on(table.ownerId, table.name),
      ownerSlugIdx: uniqueIndex("owner_slug_idx").on(table.ownerId, table.slug),
    };
  },
);

export type InsertWorkspace = typeof workspacesTable.$inferInsert;
export type SelectWorkspace = typeof workspacesTable.$inferSelect;

/**
 * Members of workspaces
 */
export const workspaceMembersTable = pgTable(
  "workspace_members",
  {
    workspaceId: varchar("workspace_id", { length: 128 }).notNull(),
    userId: varchar("user_id", { length: 128 }).notNull(),
    role: userRoles("role").notNull().default("member"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      pk: primaryKey({ columns: [table.workspaceId, table.userId] }),
    };
  },
);

export const sourceTypes = pgEnum("source_type", [
  "recording",
  "audio_file",
  "text",
]);

/**
 * Sources for generating content
 */
export const sourcesTable = pgTable("sources", {
  id: varchar("id", { length: 128 })
    .$defaultFn(() => createId())
    .primaryKey(),
  name: text("name").notNull(),
  type: sourceTypes("type").notNull(),
  content: text("content"),
  fileId: varchar("file_id", { length: 128 }),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at")
    .notNull()
    .$onUpdate(() => new Date()),
});

export type InsertSource = typeof sourcesTable.$inferInsert;
export type SelectSource = typeof sourcesTable.$inferSelect;

export const sourcesWorkspaceTable = pgTable(
  "sources_workspaces",
  {
    sourceId: varchar("source_id", { length: 128 }).notNull(),
    workspaceId: varchar("workspace_id", { length: 128 }).notNull(),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      pk: primaryKey({ columns: [table.sourceId, table.workspaceId] }),
    };
  },
);

export const sourcesPostsTable = pgTable(
  "sources_posts",
  {
    sourceId: varchar("source_id", { length: 128 })
      .notNull()
      .references(() => sourcesTable.id),
    postId: varchar("post_id", { length: 128 })
      .notNull()
      .references(() => postsTable.id),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      pk: primaryKey({ columns: [table.sourceId, table.postId] }),
    };
  },
);

export const postStatusEnum = pgEnum("post_status", [
  "draft",
  "reviewed",
  "published",
  "archived",
]);
export const postVisibility = pgEnum("post_visibility", ["private", "public"]);

export const postTypes = pgEnum("post_type", [
  "text",
  "audio",
  "video",
  "image",
]);

export const postTarget = pgEnum("post_target", [
  "linkedin",
  "x",
  "fb",
  "blog",
  "instagram",
  "other",
]);

/**
 * Posts. Generated content.
 */
export const postsTable = pgTable(
  "posts",
  {
    id: varchar("id", { length: 128 })
      .$defaultFn(() => createId())
      .primaryKey(),
    title: text("name").notNull(),
    description: text("description"),
    ownerId: varchar("owner_id", { length: 128 }).notNull(),
    status: postStatusEnum("status").notNull().default("draft"),
    visibility: postVisibility("visibility").notNull().default("private"),
    content: text("content"),
    workspaceId: varchar("workspace_id", { length: 128 }),
    schedulePostDate: timestamp("schedule_post_date"),
    sourceId: varchar("source_id", { length: 128 }),
    type: postTypes("type"),
    target: postTarget("target"),
    promptId: integer("prompt_id"),
    slug: text("slug"),
    topicId: integer("topic_id"),
    currentStep: integer("current_step").notNull().default(1),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
  },
  (table) => {
    return {
      ownerIdIdx: index("post_owner_id_idx").on(table.ownerId),
      ownerPostSlugIdx: uniqueIndex("owner_post_slug_idx").on(
        table.ownerId,
        table.slug,
        table.workspaceId,
      ),
    };
  },
);

export type InsertPost = typeof postsTable.$inferInsert;
export type SelectPost = typeof postsTable.$inferSelect;

export const userPreferencesTable = pgTable("user_preferences", {
  userId: varchar("user_id", { length: 128 }).notNull().primaryKey(),
  lastWorkspaceId: varchar("last_workspace_id", { length: 128 }),
  language: varchar("language", { length: 10 }).notNull().default("en"),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at")
    .notNull()
    .$onUpdate(() => new Date()),
});

export type InsertUserPreference = typeof userPreferencesTable.$inferInsert;
export type SelectUserPreference = typeof userPreferencesTable.$inferSelect;

export const waitListTable = pgTable(
  "wait_list",
  {
    id: serial("id").primaryKey(),
    email: text("email").notNull().unique(),
    language: varchar("language", { length: 10 }).notNull().default("en"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at")
      .notNull()
      .$onUpdate(() => new Date()),
    success: boolean("success").notNull().default(false),
  },
  (table) => {
    return {
      emailIdx: uniqueIndex("email_idx").on(table.email),
    };
  },
);

export const topicsTable = pgTable("topics", {
  id: serial("id").primaryKey(),
  title: text("title").notNull(),
  excerpt: text("excerpt"),
  content: text("content"),
  sourceId: varchar("source_id", { length: 128 }),
  rating: integer("rating").notNull().default(5),
  workspaceId: varchar("workspace_id", { length: 128 }).notNull(),
  createdBy: varchar("created_by", { length: 128 }),
  mainTopic: boolean("main_topic").notNull().default(false),
  mainTopicId: integer("main_topic_id"),
  model: varchar("model", { length: 128 }),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  isUsed: boolean("is_used").notNull().default(false),
  updatedAt: timestamp("updated_at")
    .notNull()
    .$onUpdate(() => new Date()),
});

export type InsertTopic = typeof topicsTable.$inferInsert;
export type SelectTopic = typeof topicsTable.$inferSelect;

// Relations between tables

export const usersRelations = relations(usersTable, ({ many, one }) => ({
  workspaces: many(workspaceMembersTable),
  preferences: one(userPreferencesTable, {
    fields: [usersTable.id],
    references: [userPreferencesTable.userId],
  }),
}));

export const workspacesRelations = relations(workspacesTable, ({ many }) => ({
  users: many(workspaceMembersTable),
  posts: many(postsTable),
}));

export const workspacesToGroupsRelations = relations(
  workspaceMembersTable,
  ({ one }) => ({
    group: one(workspacesTable, {
      fields: [workspaceMembersTable.workspaceId],
      references: [workspacesTable.id],
    }),
    user: one(usersTable, {
      fields: [workspaceMembersTable.userId],
      references: [usersTable.id],
    }),
  }),
);

export const audiosRelations = relations(audiosTable, ({ one }) => ({
  transcription: one(audioTranscriptsTable, {
    fields: [audiosTable.id],
    references: [audioTranscriptsTable.audioId],
  }),
}));

export const audioTranscriptsRelations = relations(
  audioTranscriptsTable,
  ({ one }) => ({
    audio: one(audiosTable, {
      fields: [audioTranscriptsTable.audioId],
      references: [audiosTable.id],
    }),
  }),
);

export const sourcesRelations = relations(sourcesTable, ({ many }) => ({
  posts: many(sourcesPostsTable),
}));

export const postsRelations = relations(postsTable, ({ one, many }) => ({
  sources: many(sourcesPostsTable),
  prompt: one(promptsTable, {
    fields: [postsTable.promptId],
    references: [promptsTable.id],
  }),
}));

export const promptRelations = relations(promptsTable, ({ many }) => ({
  categories: many(promptCategoryJoinTable),
}));

export const promptCategoryRelations = relations(
  promptCategoryJoinTable,
  ({ one }) => ({
    prompt: one(promptsTable, {
      fields: [promptCategoryJoinTable.promptId],
      references: [promptsTable.id],
    }),
    category: one(promptCategoriesTable, {
      fields: [promptCategoryJoinTable.categoryId],
      references: [promptCategoriesTable.id],
    }),
  }),
);

export const userPreferencesRelations = relations(
  userPreferencesTable,
  ({ one }) => ({
    user: one(usersTable, {
      fields: [userPreferencesTable.userId],
      references: [usersTable.id],
    }),
  }),
);

export const postsToSourcesRelations = relations(
  sourcesPostsTable,
  ({ one }) => ({
    post: one(postsTable, {
      fields: [sourcesPostsTable.postId],
      references: [postsTable.id],
    }),
    source: one(sourcesTable, {
      fields: [sourcesPostsTable.sourceId],
      references: [sourcesTable.id],
    }),
  }),
);

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Params } from "@remix-run/react";
import db from "~/db";
import { and, eq } from "drizzle-orm";
import { postsTable } from "~/db/schema";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createRequestId() {
  return Math.random().toString(36).substring(7);
}

export function createSlug(value: string) {
  const slug = value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  // Add random string to avoid conflicts
  return `${slug}-${Math.random().toString(36).substring(7)}`;
}

export function getLang(params: Params<string>) {
  const lang = params.lang ?? "en";
  if (lang !== "es" && lang !== "en") {
    throw new Response(null, {
      status: 404,
      statusText: `Not Found`,
    });
  }
  return lang;
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
}

const MAX_SLUG_RETRY = 10;

export async function generateUniqueSlug(
  title: string,
  spaceSlug: string,
): Promise<string> {
  const slug = slugify(title);
  let uniqueSlug = slug;
  let count = 1;
  let retry = 0;

  while (await doesSlugExist(uniqueSlug, spaceSlug)) {
    uniqueSlug = `${slug}-${count}`;
    count++;
    retry++;
    if (retry > MAX_SLUG_RETRY) {
      throw new Error("Failed to generate unique slug");
    }
  }

  return uniqueSlug;
}

async function doesSlugExist(
  slug: string,
  workspaceId: string,
): Promise<boolean> {
  const existingPost = await db.query.postsTable.findFirst({
    where: and(
      eq(postsTable.slug, slug),
      eq(postsTable.workspaceId, workspaceId),
    ),
  });
  console.log("Checking if slug exists", slug, existingPost);
  if (existingPost) {
    return true;
  }
  return false;
}

export async function generateUniqueTitle(
  title: string,
  spaceId: string,
): Promise<string> {
  let uniqueTitle = title;
  let count = 1;
  let retry = 0;

  while (await doesTitleExist(uniqueTitle, spaceId)) {
    uniqueTitle = `${title} ${count}`;
    count++;
    retry++;
    if (retry > MAX_SLUG_RETRY) {
      throw new Error("Failed to generate unique title");
    }
  }

  return uniqueTitle;
}

async function doesTitleExist(uniqueTitle: string, spaceId: string) {
  const exist = await db.query.postsTable.findFirst({
    where: and(
      eq(postsTable.title, uniqueTitle),
      eq(postsTable.workspaceId, spaceId),
    ),
  });

  if (exist) {
    return true;
  }

  return false;
}
